import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LoadingSpinnerService } from './shared/services/loading-spinner.service';
import { OrientationLockService } from './shared/services/orientationlock.service';
import { ToasterService } from './shared/services/toaster.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  showSpinner: boolean = false;
  spinnerMessage = 'Loading... ';
  private spinnerServiceSubscription: any;

  successMessage: string = '';
  errorMessage: string = '';
  messageStyle: any = {
    //TODO: reduce the z-index
    'z-index': 1500,
    'position': 'fixed',
    'right': 0,
    'padding': '1rem',
  };

  isDotLive: boolean = false;
  isPwa = Boolean(window.matchMedia('(display-mode: standalone)').matches);
  private observer!: MutationObserver;
  constructor(
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService,
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private orientationService: OrientationLockService,
    private elRef: ElementRef
  ) {}
  clearAlert() {
    this.successMessage = '';
    this.errorMessage = '';
  }

  ngOnInit(): void {
    screen.orientation.addEventListener('change', (event: any) => {
      this.orientationService.isLargeScreen.next(true);
    });
    this.toasterService.returnMessage().subscribe((msg) => {
      this.ngZone.run(() => {
        this.errorMessage = msg?.errorMessage;
        this.successMessage = msg?.successMessage;

        setTimeout(
          () => {
            this.clearAlert();
          },
          msg?.timeOut ? msg?.timeOut : 5000
        );
      });
    });
    this.spinnerServiceSubscription = this.spinnerService.isLoading$.subscribe(
      (isLoading) => {
        this.showSpinner = Boolean(isLoading);
        this.spinnerMessage = typeof isLoading === 'string' ? isLoading : '';

        this.cdRef.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
    this.spinnerServiceSubscription?.unsubscribe();
  }
}
